body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ui.label, .ui.labels .label {
  font-size: .85714286rem;
  background: none !important;
  padding-left: 0px;
  font-weight: 600;
  width: 100%;
}
.ui[class*="three column"].grid>.column:not(.row), .ui[class*="three column"].grid>.row>.column{
  text-align: left;
}
.button-column{
  text-align: right!important;
}